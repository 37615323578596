var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TableCustom", {
    attrs: {
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      scroll: { x: "calc(700px + 50%)" },
      paginationcustom: false,
      defaultPagination: _vm.pagination,
      loading: _vm.loading
    },
    on: { "on-tablechange": _vm.handleTableChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }