











import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IReportDailyOperationalDeliveryDTO } from "./types";

@Component
export default class UnitDelivery extends Vue {
  @Prop()
  readonly dataSourceUnitDelivery!: Pagination<IReportDailyOperationalDeliveryDTO>;

  @Prop()
  readonly loading = false;

  @Prop({ default: 1, type: Number }) readonly currentPage!: number;

  columns = [
    {
      title: this.$t("lbl_bast"),
      dataIndex: "bastNumber",
      width: "200px",
    },
    {
      title: this.$t("lbl_delivery_order_number"),
      dataIndex: "doNumber",
      width: "250px",
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
      width: "250px",
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriod",
      width: "250px",
    },
    {
      title: this.$t("lbl_branch_name"),
      dataIndex: "branchName",
      width: "250px",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      width: "200px",
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      width: "200px",
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
      width: "150px",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: "150px",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      width: "150px",
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      width: "150px",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: "150px",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: "150px",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "notes",
      width: "200px",
      scopedSlots: { customRender: "isNull" },
    },
  ];
  pagination = {
    showTotal: (total: number): string =>
      this.$t("lbl_total_items", { total }).toString(),
    current: this.currentPage,
    showSizeChanger: true,
    total: this.dataSourceUnitDelivery.totalElements ?? 0,
    pageSize: 10,
  };
  query: RequestQueryParamsModel = {
    limit: 10,
    page: 0,
    search: "",
    sorts: "",
  };

  @Watch("dataSourceUnitDelivery")
  onChangeDataSource(): void {
    this.pagination.total = this.dataSourceUnitDelivery.totalElements ?? 0;
  }

  @Watch("currentPage")
  onChangeCurrentPage(newValue: number): void {
    if (newValue === 1) this.pagination.current = 1;
  }

  get dataSource(): IReportDailyOperationalDeliveryDTO[] {
    return (
      this.dataSourceUnitDelivery?.data?.map((item, i) => ({
        ...item,
        key: i,
      })) ?? []
    );
  }

  handleTableChange(event): void {
    if (event.pagination.current !== this.pagination.current) {
      this.query.page = event.pagination.current - 1;
      this.pagination.current = event.pagination.current;
    }

    if (event.pagination.pageSize !== this.query.limit) {
      this.query.limit = event.pagination.pageSize;
      this.query.page = 0;
      this.pagination.pageSize = event.pagination.pageSize;
      this.pagination.current = 1;
    }
    this.$emit("change-page-limit", this.query);
  }
}
